<template>
  <div class="manage-project-sales">
    <div class="container fluid">
      <div class="py-2">
        <h2 class="p-3">Manage Project Sales</h2>
        <fd-tabs class="card flat" containerClass="mb-1 pt-2" route>
          <fd-route-tab
            label="Overview"
            :to="{ name: 'ManageProjectSalesOverview' }"
            iconClass="fas fa-calendar-alt"
          >
          </fd-route-tab>
          <fd-route-tab
            label="Reservation"
            :to="{ name: 'ProjectReservationsManagement' }"
            iconClass="fas fa-calendar-alt"
          >
          </fd-route-tab>
          <fd-route-tab
            label="Booking"
            :to="{ name: 'ManageProjectBooking' }"
            iconClass="fas fa-file-contract"
          >
          </fd-route-tab>
          <fd-route-tab
            label="Mortgage"
            :to="{ name: 'ManageProjectMortgage' }"
            iconClass="fas fa-dollar-sign"
          >
            <router-view></router-view>
          </fd-route-tab>
          <fd-route-tab
            label="Lawyer"
            :to="{ name: 'ManageProjectLegalProcess' }"
            iconClass="fas fa-gavel"
          >
          </fd-route-tab>
          <fd-route-tab
            label="Vacant Posession"
            :to="{ name: 'ManageProjectVacantPossession' }"
            iconClass="fas fa-key"
          >
          </fd-route-tab>
          <fd-route-tab
            label="Refund"
            :to="{ name: 'ManageProjectBookingRefunds' }"
            iconClass="fas fa-hand-holding-usd"
          >
          </fd-route-tab>
        </fd-tabs>
        <div class="card py-2">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {},
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
